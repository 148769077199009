@import '../../index.scss';

// Get Ready

section#getReady{
    padding: 192px 0;
    .container{
        align-items: flex-start;
        flex-wrap: wrap;
        .getStarted-left_col,
        .getStarted-right_col{
            width: 43%;
        }
        .getStarted-left_col{
            h3{
                margin-bottom: 40px;
            }
            p{
                font-size: 18px;
                font-weight: 400;
                color: #1E1A20;
                opacity: 0.9;
                margin-bottom: 56px;
            }
            a.btn{
                width: fit-content;
                font-weight: 700;
                font-size: 13px;
                letter-spacing: 1px;
                text-transform: uppercase;
                color: $WhiteColor;
                padding: 15px 36px;                
                background-color: $AccentColor;
                border-radius: 30px;
                &:hover{
                    color: $AccentColor;
                    background-color: $BlackColor;
                }
            }
        }
        .getStarted-right_col{
            details{
                position: relative;
                max-height: 73px;
                padding-bottom: 32px;
                border-bottom: 1px solid #1e1a2033;
                @include RegularTransition;
                &:not(:first-child){
                    margin-top: 32px;
                }
                &:before{
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 40px;
                    height: 40px;
                    background-color: $AccentColor;
                    border-radius: 40px;
                    z-index: 0;
                }
                summary,
                p{
                    color: #1E1A20;
                    opacity: 0.9;
                }
                summary{
                    display:block;
                    font-size: 24px;
                    font-weight: 600;
                    line-height: 40px;
                    &:before,
                    &:after{
                        content: '';
                        display: block;
                        position: absolute;
                        width: 40px;
                        height: 40px;
                        top: 0;
                        right: 0;
                        z-index: 2;
                        background-image: url('../../img/GetReady/minus.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: auto;
                        @include RegularTransition;
                    }
                    &:after {
                        transform: rotate(90deg);
                    }
                }
                p{
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 16px;
                }

                &[open]{
                    max-height: 1000px;
                    @include RegularTransition;
                    summary{
                        &:before {
                            transform: rotate(-180deg);
                        }
                        &:after {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }
}
  

// END Get Ready


// Adaptive
@media screen and (max-width:1280px) {}
@media screen and (max-width:1024px) {}
@media screen and (max-width:768px) {
    section#getReady{
        padding: 100px 0;
        .container{
            .getStarted-left_col,
            .getStarted-right_col{
                width: 100%;
            }
            .getStarted-left_col{
                h3{
                    margin-bottom: 20px;
                }
                p{
                    font-size: 15px;
                    padding-right: 30px;
                    margin-bottom: 32px;
                }
                a{
                    margin-bottom: 48px;
                }
            }
            .getStarted-right_col{
                details{
                    max-height: 89px;
                    summary{
                        font-size: 18px;
                        line-height: 1.56;
                        margin-right: 90px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width:650px) {}
  
  // END Adaptive