@import './index.scss';

// Container
.container{
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 1280px;
  margin: 0 auto;
}

// END Container

// Titles
h3{
  font-size: 40px;
  font-weight: 700;
  text-transform: capitalize;
  color: $DarkColor;
  margin-bottom: 16px;
}
h4{
  font-size: 32px;
  font-weight: 600;
  color: $DarkColor;
  margin-bottom: 16px;
}
h5{
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 16px;
}

// END Titles


// Adaptive
@media screen and (max-width:1280px) {
  .container{
      width: 100%;
      padding: 0 40px;
  }
}
@media screen and (max-width:1024px) {}
@media screen and (max-width:768px) {
  .container{
      padding: 0 20px;
  }
  h3{
    font-size: 28px;
  }
  h4{
    font-size: 24px;
  }
  h5{
    font-size: 18px;
  }
}
@media screen and (max-width:650px) {}

// END Adaptive