@import '../../index.scss';

// Slider
section#slider{
    padding: 104px 0;
    background-color: $SecondColor;
    .slick-slider{
        .slick-arrow{
            display: none!important;
        }
        .slick-list {
            overflow: hidden;
            margin: 0;
            padding: 0;
            .slick-slide{
                float: left;
                height: 100%;
                min-height: 1px;
                .slide-wrap{
                    position: relative;
                    height: 447px;
                    img{
                        position: absolute;
                        width: 80%;
                        top: 50%;
                        left: 0; right: 0;
                        margin: 0 auto;
                        transform: translateY(-50%);
                        z-index: -1;
                        @include RegularTransition;
                    }
                    .info-wrap{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        width: 60%;
                        height: 100%;
                        margin: 0 auto;
                        z-index: 1;
                        h5.slide-title{
                            font-size: 24px;
                            font-weight: 600;
                            text-align: center;
                            color: $WhiteColor;
                            opacity: 0.9;
                            @include RegularTransition;
                        }
                        p.slide-desc{
                            text-align: center;
                            max-height: 0px;
                            opacity: 0;
                            @include RegularTransition;
                        }
                    }
                }
                &.slick-current{
                    background-image: url('../../img/Slider/active-slide.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    .slide-wrap{
                        img{
                            position: absolute;
                            width: 150px;
                            top: 18%;
                            margin: 0 auto 0 20px;
                            // transform: translateY(-50%);
                            z-index: 0;
                        }
                        .info-wrap{
                            h5.slide-title{
                                color: #1E1A20;
                            }
                            p.slide-desc{
                                max-height: 1000px;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
    .pagination{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        p{
            font-size: 24px;
            font-weight: 400;
            color: #00000033;
            span{
                color: #000000;
            }
        }
    }
}

// END Slider

// Adaptive
@media screen and (max-width:1280px) {}
@media screen and (max-width:1024px) {}
@media screen and (max-width:768px) {
    section#slider{
        .slick-slider{
            .slick-list {
                .slick-slide{
                    .slide-wrap{
                        .info-wrap{
                            h5.slide-title{
                                font-size: 18px;
                            }
                        }
                    }
                    &.slick-current{
                        .slide-wrap{
                            img{
                                top: 25%;
                                width: 100px;
                            }
                            .info-wrap{
                                p.slide-desc{
                                    font-size: 15px;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width:650px) {}
  
  // END Adaptive