@import '../../index.scss';

// Header
header{
    margin-bottom: -112px;
    z-index: 556;
    .container{
      height: 112px;
      padding: 32px 0;
      border-bottom: 4px solid $DarkColor;
      .logo{
        display: flex;
        gap: 20px;
        img{
          @include RegularTransition;
        }
        span{
          display: block;
          position: relative;
          width: 36px;
          height: 36px;
          img{
            position: absolute;
            left: 0;
            bottom: 0;
            &:nth-child(2){
              left: 8px;
            }
            &:nth-child(3){
              left: 9px;
              bottom: -2px;
            }
          }
        }
        &:hover > img{
            filter: contrast(0.5);  
        }
      }
      nav.menu{
        @include RegularTransition;
        ul{
          display: flex;
          justify-content: space-between;
          width: 548px;
          list-style: none;
          padding: 0;
          li{
            a{
              font-weight: 400;
              font-size: 16px;
              color: #000000;
              opacity: 0.8;
              @include RegularTransition;
            }
            &.active a,
            &:hover a{
              font-weight: 700;
              opacity: 1;
            }
          }
        }
        .mob-btn{
          display: none;
        }
      }
      .btn{
        box-shadow: 0px 30px 40px rgba(240, 101, 126, 0.05);
        &:hover{
          background-color: $AccentColor;
        }
      }
      .mob__nav-button{
        display: none;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;
        width: 22px;
        height: 17px;
        z-index: 999;
        span{
          width: 22px;
          height: 3px;
          background: $BlackColor;
          border-radius: 5px;
          @include RegularTransition;
          &:nth-child(2){
            width: 17px;
          }
        }
        &.opened{
          align-items: center;
          span{
            position: absolute;
            top: 8px;
            transform: rotate(-45deg);
            @include RegularTransition;
            &:nth-child(2){
              width: 0px;
            }
            &:nth-child(3){
              transform: rotate(45deg);
            }
          }
        }
      }
    }
}

// Adaptive
@media screen and (max-width:1280px) {
  header{
    padding: 0 20px;
    .container{}
  }
}
@media screen and (max-width:1024px) {}
@media screen and (max-width:768px) {
    header{
      position: fixed;
      left: 0; right: 0;
      background: #F7F3F4;
        .container{
            nav.menu{
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: fixed;
              width: 100vw;
              height: calc(100vh - 112px);
              top: 112px; bottom: 0;
              left: 100%; right: 0;
              padding: 72px 20px;
              background: #F7F3F4;
              z-index: 555;
              ul{
                flex-direction: column;
                align-items: center;
                width: 100%;
                gap: 20px;
                li{
                  a{
                    font-size: 28px;
                    font-weight: 400;
                    opacity: 0.8;
                  }
                }
              }
              .mob-btn{
                display: block;
                width: 100%;
                text-align: center;
                margin: 0 auto;
              }
              &.active{
                left: 0px;
              }
            }
            .desc-btn{
              display: none;
            }
            .mob__nav-button{
                display: flex;
            }
        }
    }
}
@media screen and (max-width:650px) {}

// END Adaptive