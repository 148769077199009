@import '../../index.scss';

// Main
section#main{
  padding: 112px 0 90px;
  background: linear-gradient(0deg, #F7F3F4, #F7F3F4),
              linear-gradient(0deg, #FEBD2F, #FEBD2F),
              radial-gradient(178.57% 327.71% at 110.1% 3.35%, #FF605B 0%, #FFEE30 100%),
              #27D07D;
  .container{
    .main-left_col{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width:50%;
      img.title-image{
        margin-bottom: 59px;
      }
      p{
        font-weight: 400;
        font-size: 16px;
        padding-right: 120px;
        margin-bottom: 48px;
      }
      .search-label{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        background: $WhiteColor;
        padding: 8px 8px 8px 21px;
        box-shadow: 0px 30px 40px rgba(240, 101, 126, 0.05);
        border-radius: 40px;
        &:before{
          content: '';
          display: block;
          position: relative;
          min-width: 14px;
          height: 20px;
          background-image: url(../../img/Main/Location.svg);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
        }
        input{
          width: 100%;
          font-size: 14px;
          font-weight: 400;
          color: #807C7C;
          border: 0px solid transparent;
          margin-left: 13px;
          outline: 0;
          outline-offset: 0;
        }
        button{
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          font-size: 13px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $WhiteColor;
          padding: 15px 32px;
          background: $AccentColor;
          border: 0px solid transparent;
          border-radius: 30px;
          @include RegularTransition;
          &:hover{
            color: $AccentColor;
            background-color: $BlackColor;
          }
        }
      }
    }
    .main-right_col{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      width:50%;
      height: 75vh;
      padding-left: 120px;
      .parrot-image{
        position: absolute;
        right: 0;
        z-index: 0;
      }
      .main-members{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 272px;
        height: 320px;
        padding: 32px;
        background-image: url(../../img/Main/InfoWrap.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 11;
        img{
          width: 48px;
        }
        .main-members__title{
          font-weight: 700;
          font-size: 20px;
          text-transform: capitalize;
          color: $WhiteColor;
        }
        .main-members__numbs{
          font-weight: 700;
          font-size: 56px;
          color: $WhiteColor;
        }
      }
    }
  }
}

  
// Adaptive
@media screen and (max-width:1280px) {}
@media screen and (max-width:1024px) {}
@media screen and (max-width:768px) {
  section#main{
    padding: 112px 0 0;
    .container{
      padding-top: 70px;
      flex-direction: column;
      .main-left_col,
      .main-right_col{
        width: 100%;
      }
      .main-left_col{
        p{
          font-size: 15px;
          padding: 0;
        }
        .search-label{
          button{
            font-size: 12px;
          }
        }
      }
      .main-right_col{
        position: relative;
        padding-left: 0px;
        .parrot-image{
          top: 0;
          left: 130px;
        }
        .main-members{
          margin-bottom: -100px;
          .main-members__title{
            font-size: 15px;
          }
          .main-members__numbs{
            font-size: 40px;
          }
        }
      }
    }
  }
}
@media screen and (max-width:650px) {}

// END Adaptive